import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const NoticeCard = makeShortcode("NoticeCard");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <NoticeCard headingText="Coming Soon!" mdxType="NoticeCard">
      <p>{`This article is meant to provide high level guidance around the use of motion. However, motion is not currently available as tokens in Canvas.`}</p>
    </NoticeCard>
    <h2 {...{
      "id": "usage-guidance"
    }}>{`Usage Guidance`}</h2>
    <h3 {...{
      "id": "purpose"
    }}>{`Purpose`}</h3>
    <p>{`All UI animations must have a known purpose. This intent might be to solve a design problem, or to
communicate the personality of our brand. How to identify an animation’s purpose in detail will be
covered later.`}</p>
    <h3 {...{
      "id": "avoid-creating-obstacles"
    }}>{`Avoid Creating Obstacles`}</h3>
    <p>{`Animation should not get in the way of the user completing their task. An animation would never
intentionally increase the difficulty of a task, but it can be easy to get carried away while
designing something visually pleasing. Avoid extra flourish and unnecessary amounts of time.
Instead, keep animations simple, fast and direct.`}</p>
    <p>{`Loading and fetching states are an exception; there are moments when waiting can’t be avoided.`}</p>
    <h3 {...{
      "id": "deliver-delight"
    }}>{`Deliver Delight`}</h3>
    <p>{`As mentioned above, when waiting times can not be avoided, use it as an opportunity to express our
brand identity while also bringing delight to the user in moments of frustration. Fun is one of our
core values, after all.`}</p>
    <h3 {...{
      "id": "keep-it-flexible"
    }}>{`Keep It Flexible`}</h3>
    <p>{`Good interface animations need to be flexible and always feel responsive to a user’s input, even
while the animation is moving. Think of it like a conversation. If a user starts to realize their
input is being skipped or ignored, trust is lost, and the quality of their experience begins to
degrade. Non-blocking animation builds trust by always responding to the user’s input, no matter
where in the animation they currently are. The timing of the animation can also vary depending on
the size of the UI transition (that is taking place although are generally classed with a range of
200ms - 500ms.)`}</p>
    <h3 {...{
      "id": "focus-on-the-interaction"
    }}>{`Focus on the Interaction`}</h3>
    <p>{`Although animations should be fast, it is important to take your user’s task-based mindset into
account when deciding on multiple animation factors. To continue with the conversation metaphor, the
animations should always feel good to interact with. Judge this by trying out the animation for
yourself in context, as well getting outside opinions. This falls back on animations being flexible,
even if the timing is outside the recommended range, but it feels right.`}</p>
    <h3 {...{
      "id": "keep-performance-in-mind"
    }}>{`Keep Performance in Mind`}</h3>
    <p>{`Animations that are slow to interact with on the web negatively impact the experience. Poor
performance can nullify even the most thought out experience. Animations should use the most
efficient properties. Offsets can be used to lighten the cognitive load of many objects animating,
while also enhancing a user’s perceived performance.`}</p>
    <h2 {...{
      "id": "behavior"
    }}>{`Behavior`}</h2>
    <h3 {...{
      "id": "easing-values"
    }}>{`Easing Values`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Animation Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Entering Screen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ease Out`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exiting Screen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ease In`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Change in Color/Opacity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Linear 200ms`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Movement within Screen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ease`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Note: In the case where you're just fading between two colors, opacities, etc. always use a
duration of 200ms.`}</p>
    </blockquote>
    <h3 {...{
      "id": "durations-for-moving-objects"
    }}>{`Durations for Moving Objects`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "710px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/61d2b6d9c9a253bf6abae792c1bfa659/7131f/motion-table.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "106.48648648648648%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The larger the object being animated or the further the object is traveling, the longer the duration (100-500ms).",
            "title": "The larger the object being animated or the further the object is traveling, the longer the duration (100-500ms).",
            "src": "/static/61d2b6d9c9a253bf6abae792c1bfa659/7131f/motion-table.png",
            "srcSet": ["/static/61d2b6d9c9a253bf6abae792c1bfa659/1efb2/motion-table.png 370w", "/static/61d2b6d9c9a253bf6abae792c1bfa659/7131f/motion-table.png 710w"],
            "sizes": "(max-width: 710px) 100vw, 710px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "dos-and-donts-when-using-motion"
    }}>{`Do's and Don'ts when Using Motion`}</h3>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Do use values suggested in the table.`}</li>
        <li parentName="ul">{`Do create prototypes to communicate motion with developers.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Don’t deviate from the suggested easing functions.`}</li>
      </ul>
    </Dont>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      